import React, {type FC, useCallback, useMemo, useState} from 'react';
import {type Action} from 'redux';
import {useDispatch, useSelector} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {FormattedMessage, useIntl} from 'react-intl';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import Tab from 'react-bootstrap/lib/Tab';
import {useNavigate} from 'react-router-dom';
import {type ValidationError} from 'yup';

import {type AppState} from 'store/interface';
import Loader from 'components/Loader';
import {CollapsedSidebarButton} from 'components/FloatSidebar/CollapsedSidebarButton';
import {FloatSidebar} from 'components/FloatSidebar/FloatSidebar';
import {XIntroPreview} from 'components/XIntroPreview';
import {type IntroRecord} from 'store/intro/IntroRecord';
import {change, reset, save, validate, setError} from 'store/intro/actions';
import {unitPath} from 'common/paths';

import {Breadcrumbs} from './components/Breadcrumbs';
import {Sidebar} from './components/Sidebar';
import {introMessages} from './messages';
import {IntroEditor} from './components/IntroEditor';
import {Actions} from './components/Actions';

import '../XEditorPage/XEditorPage.scss';

enum XIntroTab {
  Editor = 'editor',
  Preview = 'preview'
}

export const XUnitIntroPage: FC = React.memo(() => {
  const intl = useIntl();

  const navigate = useNavigate();

  const dispatch: Dispatch<Action, AppState> = useDispatch();

  const {saving, coursebook, unit, intro, error} = useSelector((state: AppState) => ({
    saving: state.xintro.saving,
    coursebook: state.xintro.coursebook!,
    unit: state.xintro.unit!,
    intro: state.xintro.intro!,
    error: state.xintro.validationError
  }));

  const [tab, setTab] = useState(XIntroTab.Editor);

  const resetIntro = useCallback(() => dispatch(reset()), [dispatch]);

  const saveIntro = useCallback(
    (withRedirect?: boolean) => dispatch(save(intl, coursebook.id, unit.id, withRedirect)),
    [coursebook.id, dispatch, intl, unit.id]
  );
  const changeIntro = useCallback(
    (data: Partial<IntroRecord>) => dispatch(change(data)),
    [dispatch]
  );

  const validateIntro = useCallback(() => dispatch(validate(intl)), [dispatch, intl]);

  const setValidationError = useCallback(
    (error: ValidationError) => dispatch(setError(error)),
    [dispatch]
  );

  const breadcrumbs = useMemo(() => {
    return [
      {title: coursebook?.title, className: 'coursebook', icon: 'pc-book'},
      {title: unit?.title, className: 'unit'},
      {title: intl.formatMessage(introMessages.IntroPage), className: 'init-page'}
    ];
  }, [intl, coursebook, unit]);

  const onCancel = useCallback(() => {
    resetIntro();
    navigate(unitPath(coursebook.id, unit.id));
  }, [coursebook.id, navigate, resetIntro, unit.id]);

  const changeTab = useCallback(
    async selectedTab => {
      if (tab === selectedTab) return;

      const isValid = await validateIntro();

      if (isValid) {
        setTab(selectedTab as XIntroTab);
      }
    },
    [tab, validateIntro]
  );

  return (
    <React.Fragment>
      <Tab.Container id="xeditor-nav" defaultActiveKey={tab} activeKey={tab} onSelect={changeTab}>
        <div className="xeditor-page">
          <div className="xeditor-header">
            <div className="nav-breadcrumbs">
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
            <Nav bsStyle="tabs">
              <NavItem eventKey={XIntroTab.Editor} disabled={saving}>
                <FormattedMessage id="XEditor.TabItem.Editor" />
              </NavItem>
              <NavItem eventKey={XIntroTab.Preview} disabled={saving}>
                <FormattedMessage id="XEditor.TabItem.Preview" />
              </NavItem>
            </Nav>
            <Actions disabled={false} onCancel={onCancel} onSave={saveIntro} />
          </div>
          <div className="xeditor-body">
            <div className="xeditor-content">
              <Tab.Content animation={true}>
                <Tab.Pane eventKey={XIntroTab.Editor} className="editor-tab">
                  <IntroEditor
                    intro={intro}
                    error={error}
                    onChange={changeIntro}
                    setError={setValidationError}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={XIntroTab.Preview} className="player-tab" unmountOnExit={true}>
                  <XIntroPreview unit={unit} intro={intro} />
                </Tab.Pane>
              </Tab.Content>
            </div>
            <FloatSidebar
              breakpoint={1199}
              collapsedButton={(clickHandler, sidebarCollapsed) => (
                <CollapsedSidebarButton
                  onClick={clickHandler}
                  shouldBeRendered={sidebarCollapsed || false}
                />
              )}
            >
              <Sidebar intro={intro} onChange={changeIntro} />
            </FloatSidebar>
            {saving ? (
              <div className="loading-mask">
                <Loader />
              </div>
            ) : null}
          </div>
        </div>
      </Tab.Container>
    </React.Fragment>
  );
});
