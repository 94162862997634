import {type IntroRecord} from 'store/intro/IntroRecord';

interface Props {
  intro: IntroRecord;
}

export function ThemeControls({intro}: Props) {
  switch (intro.theme) {
    default:
      return null;
  }
}
