import React, {type FC, useCallback, useEffect} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage, useIntl} from 'react-intl';

import Icon from 'components/Icon';

import isShortcut from '../../../../helpers/shortcut';

import '../../XEditorPage/XEditorActions.scss';

const SaveShortcut = 'mod+s';

interface Props {
  disabled: boolean;
  onCancel: () => void;
  onSave: (withRedirect?: boolean) => void;
}

export const Actions: FC<Props> = React.memo(({disabled, onCancel, onSave}) => {
  const {formatMessage} = useIntl();

  const onKeyDown = useCallback(
    event => {
      if (isShortcut(event, SaveShortcut)) {
        event.preventDefault();
        onSave(false);
      }
    },
    [onSave]
  );

  const onSaveWithRedirect = useCallback(() => onSave(true), [onSave]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <div className="xeditor-actions">
      <Button
        bsSize="sm"
        className="btn-transparent"
        onClick={onCancel}
        disabled={disabled}
        title={formatMessage({id: 'XEditor.Cancel'})}
      >
        <FormattedMessage id="XEditor.Cancel" />
        <Icon name="pc-close" />
      </Button>

      <Button
        bsSize="sm"
        bsStyle="success"
        disabled={disabled}
        onClick={onSaveWithRedirect}
        className="save-exercise-button"
        title={formatMessage({id: 'XEditor.Save'})}
      >
        <FormattedMessage id="XEditor.Save" />
        <Icon name="save" />
      </Button>
    </div>
  );
});
