import React, {useCallback, useState} from 'react';
import {type Editor} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import {ValidationError} from 'yup';

import {type IntroRecord} from 'store/intro/IntroRecord';

import {introMessages} from '../../messages';
import Undo from '../../../../../components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from '../../../../../components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from '../../../../../components/Slate/SlateEditor/plugins/button/Bold';
import Italic from '../../../../../components/Slate/SlateEditor/plugins/button/Italic';
import Underline from '../../../../../components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from '../../../../../components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FormatPainter from '../../../../../components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from '../../../../../components/Slate/SlateEditor/plugins/button/ClearFormatting';
import TextAlignment from '../../../../../components/Slate/SlateEditor/plugins/button/TextAlignment';
import FontSizeToolbar from '../../../../../components/Slate/SlateEditor/plugins/button/FontSize';
import FontColorToolbar from '../../../../../components/Slate/SlateEditor/plugins/button/Color';
import HighlightToolbar from '../../../../../components/Slate/SlateEditor/plugins/button/Highlight';
import {NestedLists} from '../../../../../components/Slate/SlateEditor/plugins/button/List/NestedLists';
import CharSelector from '../../../../../components/Slate/SlateEditor/plugins/button/CharSelector';
import Link from '../../../../../components/Slate/SlateEditor/plugins/button/Link';
import SlateEditor from '../../../../../components/Slate/SlateEditor/SlateEditor';
import ImageCropUploader from '../../../../../common/ImageUpload/ImageCropUploader';
import {useCropModal} from '../../../hooks/useCropModal';
import CropModal from '../../../XEditorPage/components/XEditor/xwidgets/components/CropModal';
import {ImageInput} from '../../../XEditorPage/components/XEditor/xwidgets/components/ImageInput';
import CropControls from '../../../../../components/Slate/SlateEditor/plugins/button/Image/Modal/CropButtons';
import {AspectRatio} from '../../../../../components/Crop/static';

const IMAGE_MIN_DIMENSION = 794;

const LOCKED_ASPECT_RATIOS: AspectRatio[] = [];

const TextPlugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new TextAlignment(),
  new FontSizeToolbar(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new NestedLists(),
  new CharSelector(),
  new Link(),
  new FormatPainter(),
  new ClearFormatting()
];

interface Props {
  intro: IntroRecord;
  onChange: (data: Partial<IntroRecord>) => void;
  setError: (error: ValidationError) => void;
}

export function BasicTheme({intro, onChange, setError}: Props) {
  const intl = useIntl();

  const [aspectRatio, setAspectRatio] = useState(AspectRatio.ORIGINAL);

  const onError = useCallback(
    errorMessage => setError(new ValidationError(errorMessage, null, 'image')),
    [setError]
  );

  const onChangeContent = useCallback(
    (editor: Editor) => onChange({content: editor.value}),
    [onChange]
  );

  const onChangeImage = useCallback((image: number) => onChange({image}), [onChange]);

  const {
    imageDataUrl,
    errorMessage,
    cropResult,
    freezeModal,
    isErrorStatus,
    retry,
    status,
    uploadingStarted,
    validatedFile,
    hideModal,
    storeCrop,
    storeFile,
    startUploading,
    handleFileSelected,
    handleError,
    handleResponse,
    turnOffRetry,
    setStatus
  } = useCropModal({onSuccess: onChangeImage, onError, imageMinDimension: IMAGE_MIN_DIMENSION});

  return (
    <div className={classNames('xintro-theme', intro.theme)}>
      <div className="theme-content">
        <div className="theme-content__image">
          <div className={classNames('img-block', {'with-image': !!intro.image})}>
            {imageDataUrl ? (
              <CropModal
                aspect={aspectRatio}
                minWidth={IMAGE_MIN_DIMENSION}
                hide={hideModal}
                imageDataUrl={imageDataUrl}
                storeCrop={storeCrop}
                renderFooter={() => (
                  <div className="image-upload-controls">
                    <Button bsSize="small" onClick={hideModal}>
                      <FormattedMessage id="Slate.Modal.Image.CancelButton" />
                    </Button>
                    {uploadingStarted ? (
                      <ImageCropUploader
                        errorMessage={errorMessage}
                        file={validatedFile!}
                        handleError={handleError}
                        handleResponse={handleResponse}
                        isErrorStatus={isErrorStatus}
                        crop={cropResult!}
                        setStatus={setStatus}
                        shouldRetry={retry}
                        turnOffRetry={turnOffRetry}
                        status={status}
                      />
                    ) : (
                      <CropControls
                        intl={intl}
                        aspectRatio={aspectRatio}
                        lockedAspectRatios={LOCKED_ASPECT_RATIOS}
                        setAspectRatio={setAspectRatio}
                      />
                    )}

                    <Button
                      bsSize="small"
                      bsStyle="primary"
                      onClick={startUploading}
                      disabled={freezeModal}
                    >
                      <FormattedMessage id="Common.Upload" />
                    </Button>
                  </div>
                )}
              />
            ) : (
              <ImageInput
                id={intro.theme}
                handleFileSelected={handleFileSelected}
                imageId={intro.image}
                storeFile={storeFile}
              />
            )}
          </div>
        </div>

        <div className="theme-content__text">
          <SlateEditor
            toolbar={{portalId: 'xintro-toolbar-portal'}}
            value={intro.content}
            onChange={onChangeContent}
            plugins={TextPlugins}
            placeholder={intl.formatMessage(introMessages.ContentPlaceholder)}
          />
        </div>
      </div>
    </div>
  );
}
