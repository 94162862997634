import React, {type FC, useCallback, useMemo} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {useIntl} from 'react-intl';

import ReactSelect from 'components/ReactSelect/ReactSelect';
import {type IntroRecord} from 'store/intro/IntroRecord';
import {IntroTheme} from 'common/enums';

import {introMessages} from '../messages';
import {ThemeControls} from './ThemeControls';

import '../../XEditorPage/components/Sidebar/styles.scss';

interface Props {
  intro: IntroRecord;
  onChange: (data: Partial<IntroRecord>) => void;
}

function propsAreEqual(prevProps: Props, nextProps: Props) {
  return prevProps.intro.theme === nextProps.intro.theme;
}

export const Sidebar: FC<Props> = React.memo(({intro, onChange}) => {
  const intl = useIntl();

  const themes = useMemo(
    () => [{id: IntroTheme.Basic, label: intl.formatMessage(introMessages.ThemeBasic)}],
    [intl]
  );

  const theme = themes.find(o => o.id === intro.theme);

  const onChangeTheme = useCallback(value => onChange({theme: value.id}), [onChange]);

  return (
    <div className="sidebar">
      <Scrollbars>
        <div className="wrapper">
          <div className="categories">
            <section>
              <span className="section-title">
                {intl.formatMessage(introMessages.VisualPageTheme)}:
              </span>
              <ReactSelect
                value={theme}
                options={themes}
                onChange={onChangeTheme}
                isMulti={false}
                isClearable={false}
                isSearchable={false}
              />
            </section>
            <section>
              <ThemeControls intro={intro} />
            </section>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}, propsAreEqual);
