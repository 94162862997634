import React, {useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {type AppState} from 'store/interface';
import {loadIntroData, reset} from 'store/intro/actions';

import {XUnitIntroPage} from './XUnitIntroPage';
import Loader from '../../../components/Loader';
import WampErrorMask from '../../../components/WampErrorMask';

type Params = {
  coursebookId: string;
  unitId: string;
};

function XIntroRoute() {
  const {coursebookId, unitId: unit} = useParams<Params>();

  const unitId = Number(unit);

  const dispatch = useDispatch();

  const {loading, error} = useSelector(({xintro}: AppState) => ({
    loading: xintro.loading,
    error: xintro.error
  }));

  const loadData = useCallback(() => {
    if (Number.isNaN(unitId)) return;

    dispatch(loadIntroData(coursebookId!, unitId));

    return () => {
      dispatch(reset());
    };
  }, [dispatch, coursebookId, unitId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return error ? <WampErrorMask reload={loadData} /> : loading ? <Loader /> : <XUnitIntroPage />;
}

export default XIntroRoute;
