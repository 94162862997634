import React, {useMemo} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import Scrollbars from 'react-custom-scrollbars';
import {useIntl} from 'react-intl';
import {type ValidationError} from 'yup';
import classNames from 'classnames';

import {type IntroRecord} from 'store/intro/IntroRecord';
import {IntroTheme} from 'common/enums';

import {BasicTheme} from './BasicTheme';
import {introMessages} from '../../messages';
import ErrorMessage from '../../../XEditorPage/components/ValidationError';

import './IntroEditor.scss';

interface Props {
  intro: IntroRecord;
  error?: ValidationError;
  onChange: (data: Partial<IntroRecord>) => void;
  setError: (error: ValidationError) => void;
}

export function IntroEditor({intro, error, onChange, setError}: Props) {
  const {formatMessage} = useIntl();

  const ThemeContent = useMemo(() => {
    switch (intro.theme) {
      case IntroTheme.Basic:
        return BasicTheme;
      default:
        throw new Error('Unknown intro theme');
    }
  }, [intro.theme]);

  return (
    <div className="xintro">
      <ReactResizeDetector refreshRate={16} refreshMode="throttle">
        <div className="xintro-canvas-wrapper">
          <Scrollbars>
            <div className="xintro-canvas">
              <div className={classNames('xintro-content', {'with-error': Boolean(error)})}>
                <div className="xintro-header">{formatMessage(introMessages.EditorHeader)}</div>
                <ThemeContent intro={intro} onChange={onChange} setError={setError} />
              </div>
            </div>
            <ErrorMessage message={error?.message} className="xintro-error" />
          </Scrollbars>
        </div>
      </ReactResizeDetector>
      <div id="xintro-toolbar-portal" className="xintro-toolbar-portal" />
    </div>
  );
}
