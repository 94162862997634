import React, {type FC} from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

import BreadcrumbLine from '../../../../components/BreadcrumbLine';

interface Breadcrumb {
  title: string;
  icon?: string;
  className?: string;
}

interface Props {
  breadcrumbs: Breadcrumb[];
}

function BreadcrumbsAreEqual(prevProps: Props, nextProps: Props) {
  if (prevProps.breadcrumbs.length && nextProps.breadcrumbs.length) {
    return prevProps.breadcrumbs.every(
      (breadcrumb, index) => breadcrumb.title === nextProps.breadcrumbs[index].title
    );
  }

  return true;
}

export const Breadcrumbs: FC<Props> = React.memo(({breadcrumbs}) => {
  return (
    <React.Fragment>
      {breadcrumbs.map(({title, icon, className}) => (
        <div key={title} className={classNames('breadcrumb', className)}>
          {icon && <Icon name={icon} />}
          <span className="title" title={title}>
            {title}
          </span>
          <BreadcrumbLine />
        </div>
      ))}
    </React.Fragment>
  );
}, BreadcrumbsAreEqual);
